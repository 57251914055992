const FloatingOrbs = () => {
  return (
    <>
      <div className="absolute top-20 right-20 w-[600px] h-[600px] bg-amber-500/10 rounded-full blur-[150px]" />
      <div className="absolute -bottom-32 -left-32 w-[700px] h-[700px] bg-orange-600/10 rounded-full blur-[180px]" />
    </>
  );
};

export default FloatingOrbs;
