import HeroHeading from "./HeroHeading";
import HeroButtons from "./HeroButtons";
import HeroCarousel from "./HeroCarousel";
import Stats from "../Stats";

const HeroContent = () => {
  return (
    <div className="relative z-10 desktop:px-24 laptop:px-16 tablet:px-8 mobile:px-4 min-h-screen flex items-center">
      <div className="max-w-7xl mx-auto w-full desktop:py-20 laptop:py-20 tablet:py-16 mobile:py-8">
        <div className="grid desktop:grid-cols-12 laptop:grid-cols-12 tablet:grid-cols-1 mobile:grid-cols-1 desktop:gap-16 laptop:gap-16 tablet:gap-12 mobile:gap-8 items-center">
          <div className="desktop:col-span-7 laptop:col-span-7 tablet:col-span-12 mobile:col-span-12">
            <div className="opacity-100 transform translate-x-0 transition-all duration-500">
              <HeroHeading />
              <HeroButtons />
              <Stats />
            </div>
          </div>
          <HeroCarousel />
        </div>
      </div>
    </div>
  );
};

export default HeroContent;
