import Logo from "./Logo";

const Navigation = () => {
  return (
    <nav className="absolute top-0 left-0 w-full z-50 desktop:px-8 laptop:px-8 tablet:px-6 mobile:px-3 desktop:py-6 laptop:py-6 tablet:py-4 mobile:py-4">
      <div className="max-w-7xl mx-auto">
        {" "}
        <Logo />
      </div>
    </nav>
  );
};

export default Navigation;
