import { Expert } from "../../../../types";

export const gabrielHubert: Expert = {
  name: "Gabriel Hubert",
  background:
    "Gabriel Hubert is a seasoned entrepreneur in the tech industry, currently known as the co-founder of Dust, a startup specializing in leveraging generative AI to enhance workplace productivity. Previously, he co-founded a data analytics company that Stripe acquired in 2014, where he later worked as a product lead. Following Stripe, he joined Alan, a French health insurance startup, also as a product leader, before venturing into AI with Dust in 2023. Under his guidance, Dust secured significant funding from Sequoia Capital and other notable investors to develop safe and efficient AI applications for enterprises",
  imageUrl:
    "https://createsiteai.b-cdn.net/c/_/5437d734-ef10-41df-b1b8-8cdeac92d64d",
  event: {
    date: "November 19th, 2024",
    time: "8:30 PM - 10:30 PM",
    location: "Brasserie Rosie, Paris 11ème",
  },
  company: {
    name: "Dust",
    description:
      "Dust builds custom AI assistants to enhance team productivity by connecting them to proprietary data and workflows. It enables businesses to create task-specific AI tools for improved customer support, engineering, data management, and more. Dust's platform integrates with popular tools, allowing flexible, model-agnostic AI applications that fit seamlessly into existing systems.",
    website: "https://dust.tt/",
  },
  socials: [
    { type: "linkedin", url: "https://www.linkedin.com/in/gabhubert/" },
    {
      type: "youtube",
      url: "https://www.youtube.com/watch?v=X7hIbtM_7f4&t=29s",
    },
  ],
};
