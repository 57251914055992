export const WeekdayHeader = () => {
  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  return (
    <div className="grid grid-cols-7 gap-2 mb-4">
      {weekdays.map((day) => (
        <div
          key={day}
          className="text-center text-amber-200/60 font-medium py-2"
        >
          {day}
        </div>
      ))}
    </div>
  );
};
