const HeroHeading = () => {
  return (
    <>
      <div className="inline-block mb-8 desktop:mt-0 laptop:mt-0 tablet:mt-0 mobile:mt-16">
        <span className="bg-gradient-to-r from-amber-500/10 via-stone-800/20 to-stone-900/20 text-amber-200/90 desktop:text-lg laptop:text-lg tablet:text-base mobile:text-sm px-8 py-3 rounded-full border border-amber-500/20 backdrop-blur-sm shadow-lg shadow-amber-900/20">
          Weekly AI Dinners • Paris
        </span>
      </div>
      <h1 className="desktop:text-7xl laptop:text-6xl tablet:text-5xl mobile:text-2xl font-bold mb-8 leading-[1.1]">
        <span className="text-white">Where Great Minds</span>
        <br />
        <span className="bg-gradient-to-r from-amber-200 via-orange-200 to-amber-200 text-transparent bg-clip-text">
          Share Great Meals
        </span>
      </h1>
      <p className="desktop:text-xl laptop:text-xl tablet:text-lg mobile:text-sm text-amber-100/80 desktop:mb-12 laptop:mb-12 tablet:mb-8 mobile:mb-6 leading-relaxed max-w-2xl font-light">
        Join our intimate weekly gatherings where AI innovation meets culinary
        excellence. Connect with leading AI experts and fellow students over
        thoughtfully crafted meals in a warm, welcoming atmosphere.
      </p>
    </>
  );
};

export default HeroHeading;
