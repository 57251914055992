import { experts } from "../../../DinerDetails/data/experts";

interface EventHeaderProps {
  title: string;
  expert: string;
}

export const EventHeader = ({ title, expert }: EventHeaderProps) => (
  <div className="p-4 border-b border-stone-700/20">
    <h4 className="text-white text-lg font-medium mb-1">{title}</h4>
    <p className="text-amber-200/60 text-sm">
      {expert} - {experts.find((e) => e.name === expert)?.company.name}
    </p>
  </div>
);
