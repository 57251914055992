export const Background = () => (
  <div className="absolute inset-0 z-0">
    <div
      className="absolute inset-0 scale-105"
      style={{
        backgroundImage: `url('https://createsiteai.b-cdn.net/c/_/06ceebbb-5d7f-4c11-9a55-908890294cdc')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        filter: "brightness(0.3)",
      }}
    />
    <div className="absolute inset-0 bg-gradient-to-br from-amber-950/80 via-stone-900/75 to-stone-950/85" />
    <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_center,rgba(251,191,36,0.15)_0%,rgba(120,53,15,0.1)_50%,rgba(0,0,0,0)_100%)]" />
  </div>
);
