import { OptimizedImage } from "../../../../../components/OptimizedImage";
import { Expert } from "../../../data/experts";
import { EventDetails } from "../../components/EventDetails";
import { LocationMap } from "../../components/LocationMap";
import { BookingButton } from "../../components/BookingButton";
import { SocialLinks } from "../components/SocialLinks";

interface MobileLayoutProps {
  expert: Expert;
}

export const MobileLayout = ({ expert }: MobileLayoutProps) => {
  return (
    <div className="desktop:hidden laptop:hidden tablet:hidden mobile:block space-y-8">
      <div>
        <div className="space-y-2">
          <h2 className="text-white text-4xl font-semibold">{expert.name}</h2>
          <p className="text-amber-200/60 text-xl">{expert.company.name}</p>
        </div>
      </div>

      <div>
        <OptimizedImage
          src={expert.imageUrl}
          alt={expert.name}
          className="rounded-2xl border border-stone-700/20"
        />
      </div>

      <div>
        <div className="space-y-4">
          <h3 className="text-white text-2xl font-semibold">
            About the Expert
          </h3>
          <p className="text-amber-100/80 text-lg desktop:text-left laptop:text-left tablet:text-left mobile:text-justify mobile:hyphens-auto">
            {expert.background}
          </p>
          <div className="pt-4">
            <SocialLinks socials={expert.socials} />
          </div>
        </div>
      </div>

      <div>
        <div className="space-y-4">
          <h3 className="text-white text-2xl font-semibold">Company</h3>
          <p className="text-amber-100/80 text-lg desktop:text-left laptop:text-left tablet:text-left mobile:text-justify mobile:hyphens-auto">
            {expert.company.description}
          </p>
          <a
            href={expert.company.website}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-stone-800/30 hover:bg-stone-800/40 text-white text-sm font-medium px-6 py-2 rounded-xl backdrop-blur-sm border border-white/10 hover:border-amber-500/30 transition-transform duration-300 hover:scale-105"
          >
            Visit Website
          </a>
        </div>
      </div>

      <EventDetails
        date={expert.event.date}
        time={expert.event.time}
        location={expert.event.location}
      />

      <LocationMap />

      <BookingButton />
    </div>
  );
};
