import { FaLinkedin, FaGithub, FaGlobe, FaYoutube } from "react-icons/fa";

type Social = {
  type: "linkedin" | "github" | "website" | "youtube";
  url: string;
};

interface SocialLinksProps {
  socials: Social[];
}

export const SocialLinks = ({ socials }: SocialLinksProps) => {
  const iconMap = {
    linkedin: FaLinkedin,
    github: FaGithub,
    website: FaGlobe,
    youtube: FaYoutube,
  };

  return (
    <div className="flex gap-4 mt-4">
      {socials.map((social, index) => {
        const Icon = iconMap[social.type];
        return (
          <a
            key={index}
            href={social.url}
            target="_blank"
            rel="noopener noreferrer"
            className="p-3 rounded-full bg-stone-800/80 hover:bg-stone-700/80 text-amber-200/80 backdrop-blur-sm border border-stone-700/30 transition-all duration-300 hover:scale-110"
          >
            <Icon size={24} />
          </a>
        );
      })}
    </div>
  );
};
