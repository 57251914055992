import { Expert } from "../../../../types";

export const laurentMazare: Expert = {
  name: "Laurent Mazare",
  background:
    "Laurent Mazare began his career in quantitative finance, working at prestigious firms like Jane Street and Goldman Sachs, where he focused on statistical modeling and algorithmic trading. He made a brief stop at Google DeepMind, gaining valuable experience in AI research and development. Moving into tech, he contributed to the open-source community by developing tch-rs, a Rust library for PyTorch, which has been widely used for machine learning applications. Currently, Laurent is the co-founder and CTO of Kyutai, a non-profit AI research lab dedicated to creating advanced speech-to-speech models for real-time conversational AI",
  imageUrl:
    "https://createsiteai.b-cdn.net/c/_/d6025773-9994-45fe-91f6-27157dd8d699",
  event: {
    date: "TBD",
    time: "8:30 PM - 10:30 PM",
    location: "Brasserie Rosie, Paris 11ème",
  },
  company: {
    name: "Kyutai",
    description:
      "Kyutai is an AI research lab based in Paris, focused on building and democratizing Artificial General Intelligence (AGI) through open science. The lab develops advanced multimodal AI models, including their key project, Moshi, a speech-to-speech model for real-time conversation. Their mission is to contribute to the broader AI community by making cutting-edge research and technology accessible and open. Kyutai operates under the principles of open science, sharing their code and findings publicly to foster collaboration and innovation in the AI field.",
    website: "https://kyutai.org/",
  },
  socials: [
    {
      type: "linkedin",
      url: "https://www.linkedin.com/in/laurent-mazare-ab01a53/",
    },
    { type: "youtube", url: "https://www.youtube.com/watch?v=hm2IJSKcYvo" },
  ],
};
