import { useState } from "react";
import DinerCard from "../DinerCard";
import { CarouselButton } from "./CarouselButton";
import { experts } from "../../pages/DinerDetails/data/experts";

export const DinerCarousel = () => {
  const [[page, direction], setPage] = useState([0, 0]);

  const paginate = (newDirection: number) => {
    const newPage = (page + newDirection + experts.length) % experts.length;
    setPage([newPage, newDirection]);
  };

  return (
    <div className="relative w-full">
      <CarouselButton direction="left" onClick={() => paginate(-1)} />
      <CarouselButton direction="right" onClick={() => paginate(1)} />
      <div className="opacity-100 transform translate-x-0 transition-all duration-500">
        <DinerCard key={page} expert={experts[page]} />
      </div>
    </div>
  );
};
