import { Expert } from "../../../types";
import {
  gabrielHubert,
  thomasScialom,
  rachidGuerraoui,
  pierreLouisBoijout,
  hamzaSayah,
  antoineCarossio,
  brivaelLePogam,
  laurentMazare,
} from "./experts";

export const experts: Expert[] = [
  gabrielHubert,
  thomasScialom,
  rachidGuerraoui,
  pierreLouisBoijout,
  hamzaSayah,
  antoineCarossio,
  brivaelLePogam,
  laurentMazare,
];
