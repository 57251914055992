import Logo from "../../components/Navigation/Logo";
import { BackgroundLayer } from "./components/BackgroundLayer";
import { useEffect } from "react";

export const RegisterPage = () => {
  // Add Tally script when component mounts
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://tally.so/widgets/embed.js";
    script.async = true;
    script.onload = () => {
      // @ts-ignore - Tally is injected globally
      if (typeof Tally !== "undefined") {
        // @ts-ignore - Tally is injected globally
        Tally.loadEmbeds();
      }
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="relative min-h-screen overflow-hidden">
      <BackgroundLayer />
      <div className="absolute top-0 left-0 w-full z-50 px-8 py-8">
        <div className="max-w-7xl mx-auto">
          <Logo />
        </div>
      </div>
      <div className="relative z-10 desktop:px-24 laptop:px-16 tablet:px-8 mobile:px-4 min-h-screen flex items-start justify-center pt-32">
        <div className="w-full max-w-xl opacity-100 scale-100">
          <div className="bg-gradient-to-br from-stone-800/40 via-stone-900/40 to-black/40 rounded-3xl p-8 border border-stone-700/20 backdrop-blur-md shadow-2xl">
            <h2 className="text-white text-3xl font-semibold mb-8">
              Reserve Your Seat
            </h2>
            <iframe
              data-tally-src="https://tally.so/embed/mKB70k?hideTitle=1&transparentBackground=1&dynamicHeight=1"
              loading="lazy"
              width="100%"
              height="873"
              frameBorder="0"
              marginHeight={0}
              marginWidth={0}
              title="Registration form"
              className="w-full"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};
