import { Link } from "react-router-dom";
import { Expert } from "../pages/DinerDetails/types";
import { events } from "../pages/Calendar/data/events";

interface DinerCardProps {
  expert: Expert;
}

const DinerCard = ({ expert }: DinerCardProps) => {
  return (
    <Link to={`/diner/${expert.name.toLowerCase().replace(/\s+/g, "-")}`}>
      <div className="bg-gradient-to-br from-stone-800/40 via-stone-900/40 to-black/40 rounded-3xl desktop:p-8 laptop:p-8 tablet:p-6 mobile:p-3 border border-stone-700/20 backdrop-blur-md shadow-2xl w-full desktop:h-[800px] laptop:h-[800px] tablet:h-[700px] mobile:h-[600px] flex flex-col overflow-hidden">
        <div className="opacity-100 transform translate-x-0 transition-all duration-500">
          <div className="relative aspect-[4/3] w-full rounded-2xl overflow-hidden desktop:mb-8 laptop:mb-8 tablet:mb-6 mobile:mb-4 border border-stone-700/20">
            <img
              src={expert.imageUrl}
              alt={expert.name}
              className="w-full h-full object-cover object-center transform hover:scale-105 transition-transform duration-700"
              style={{
                aspectRatio: "4/3",
                objectPosition: "center 25%",
              }}
            />
          </div>
          <h3 className="text-white desktop:text-2xl laptop:text-2xl tablet:text-xl mobile:text-lg font-semibold mb-6">
            {events.find((e) => e.expert === expert.name)?.title ||
              "Next Gathering"}
          </h3>
          <div className="space-y-5">
            {[
              { label: "Date", value: expert.event.date },
              { label: "Time", value: expert.event.time },
              { label: "Location", value: expert.event.location },
              { label: "Guest Expert", value: expert.name },
              { label: "Company", value: expert.company.name },
            ].map((item, index) => (
              <div
                key={index}
                className="flex justify-between items-center py-2 border-b border-stone-700/20"
              >
                <span className="text-amber-200/60 font-light">
                  {item.label}
                </span>
                <span className="text-white/90 font-medium">
                  {item.label === "Location" ? (
                    <>
                      <span className="desktop:block laptop:block tablet:block mobile:hidden">
                        {item.value}
                      </span>
                      <span className="desktop:hidden laptop:hidden tablet:hidden mobile:block">
                        Brasserie Rosie, Paris
                      </span>
                    </>
                  ) : (
                    item.value
                  )}
                </span>
              </div>
            ))}
          </div>
          <div className="mt-auto pt-8 flex justify-between items-center">
            <span className="inline-block bg-emerald-500/10 text-emerald-300 desktop:px-4 laptop:px-4 tablet:px-4 mobile:px-2 desktop:py-2 laptop:py-2 tablet:py-2 mobile:py-1 rounded-full desktop:text-sm laptop:text-sm tablet:text-sm mobile:text-xs border border-emerald-500/20">
              10 Seats Available
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default DinerCard;
