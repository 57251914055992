import { useParams } from "react-router-dom";
import Logo from "../../../../components/Navigation/Logo";
import { Background } from "../../components/Background";
import { experts } from "../../data/experts";
import { DesktopLayout } from "./layouts/DesktopLayout";
import { MobileLayout } from "./layouts/MobileLayout";

export const DinerDetails = () => {
  const { expertName } = useParams();
  const expert = experts.find(
    (e) => e.name.toLowerCase().replace(/\s+/g, "-") === expertName,
  );

  return (
    <div className="relative min-h-screen overflow-hidden">
      <Background />
      <div className="absolute top-0 left-0 w-full z-50 px-8 py-6">
        <div className="max-w-7xl mx-auto">
          <Logo />
        </div>
      </div>
      <div className="relative z-10 desktop:px-24 laptop:px-16 tablet:px-8 mobile:px-4 min-h-screen">
        <div className="max-w-7xl mx-auto py-16">
          <div className="bg-gradient-to-br from-stone-800/40 via-stone-900/40 to-black/40 rounded-3xl p-8 border border-stone-700/20 backdrop-blur-md shadow-2xl">
            <DesktopLayout expert={expert} />
            <MobileLayout expert={expert} />
          </div>
        </div>
      </div>
    </div>
  );
};
