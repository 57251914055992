interface CompanySectionProps {
  description: string;
  website: string;
}

export const CompanySection = ({
  description,
  website,
}: CompanySectionProps) => {
  return (
    <div>
      <h3 className="text-white text-3xl font-semibold mb-4">Companies</h3>
      <p className="text-amber-100/80 text-2xl mb-4 desktop:text-left laptop:text-left tablet:text-left mobile:text-justify mobile:hyphens-auto">
        {description}
      </p>
      <a
        href={website}
        target="_blank"
        rel="noopener noreferrer"
        className="inline-block bg-stone-800/30 hover:bg-stone-800/40 text-white text-sm font-medium px-6 py-2 rounded-xl backdrop-blur-sm transition-all duration-300 border border-white/10 hover:border-amber-500/30 hover:scale-102"
      >
        Visit Website
      </a>
    </div>
  );
};
