import Background from "./Background";
import FloatingOrbs from "./FloatingOrbs";
import HeroContent from "./HeroContent";
import Navigation from "../Navigation";

const Hero = () => {
  return (
    <div className="relative min-h-screen overflow-hidden relative">
      <Background />
      <FloatingOrbs />
      <Navigation />
      <HeroContent />
    </div>
  );
};

export default Hero;
