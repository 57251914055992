import { CalendarEvent } from "../../../types";
import { MobileView } from "./MobileView";
import { DesktopView } from "./DesktopView";

interface CalendarCellProps {
  index: number;
  event: CalendarEvent | undefined;
  onClick: () => void;
}

export const CalendarCell = ({ index, event, onClick }: CalendarCellProps) => (
  <div
    className={`aspect-square p-2 rounded-xl transform hover:scale-105 transition-all duration-300 ${
      event
        ? "bg-amber-500/20 border border-amber-500/30"
        : "bg-stone-800/30 border border-stone-700/20"
    }`}
    onClick={onClick}
  >
    <div
      className={`h-full desktop:flex laptop:flex tablet:flex mobile:block ${
        event ? "cursor-pointer" : "cursor-default"
      }`}
    >
      <MobileView index={index} event={event} />
      <DesktopView index={index} event={event} />
    </div>
  </div>
);
