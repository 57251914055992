import { Link } from "react-router-dom";
import { CalendarEvent } from "../../types";
import { experts } from "../../../DinerDetails/data/experts";
import { EventButtons } from "./EventButtons";

interface DesktopEventCardProps {
  event: CalendarEvent;
  index: number;
}

export const DesktopEventCard = ({ event, index }: DesktopEventCardProps) => (
  <div className="p-6 rounded-2xl bg-gradient-to-br from-stone-800/40 via-stone-900/40 to-stone-900/40 border border-stone-700/20 backdrop-blur-sm hover:border-amber-500/20 transition-all duration-300 mb-4">
    <div className="grid desktop:grid-cols-4 laptop:grid-cols-4 tablet:grid-cols-2 mobile:grid-cols-1 gap-4">
      <div className="col-span-1">
        <h4 className="text-white text-lg font-medium mb-2">{event.title}</h4>
        <p className="text-amber-200/60 text-sm">
          {event.expert} -{" "}
          {experts.find((e) => e.name === event.expert)?.company.name}
        </p>
      </div>
      <div className="col-span-1 space-y-1">
        <p className="text-amber-200/80 text-sm">{event.date}</p>
        <p className="text-amber-200/60 text-sm">{event.time}</p>
        <p className="text-amber-200/60 text-sm">{event.location}</p>
      </div>
      <div className="col-span-2 flex items-center gap-4 desktop:justify-end laptop:justify-end tablet:justify-start mobile:justify-start">
        <span className="inline-block bg-emerald-500/10 text-emerald-300 px-4 py-2 rounded-full text-sm border border-emerald-500/20">
          {event.availableSeats} Seats Available
        </span>
        <EventButtons
          expertSlug={event.expert.toLowerCase().replace(/\s+/g, "-")}
        />
      </div>
    </div>
  </div>
);
