import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Home from "./pages/Home";
import Register from "./pages/Register";
import Calendar from "./pages/Calendar";
import SubmissionReceived from "./pages/SubmissionReceived";
import RegistrationComplete from "./pages/RegistrationComplete";
import RegistrationFailed from "./pages/RegistrationFailed";
import DinerDetails from "./pages/DinerDetails";
import { ScrollToTop } from "./components/ScrollToTop";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/submission-received" element={<SubmissionReceived />} />
        <Route
          path="/registration-complete"
          element={<RegistrationComplete />}
        />
        <Route path="/diner/:expertName" element={<DinerDetails />} />
        <Route path="/registration-failed" element={<RegistrationFailed />} />
      </Routes>
    </Router>
  );
}

export default App;
