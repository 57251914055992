import { Expert } from "../../../../types";

export const brivaelLePogam: Expert = {
  name: "Brivael le Pogam",
  background:
    "Brivael Le Pogam is a co-founder of Argil, an AI-driven platform that simplifies video production by generating avatars and creating professional-quality videos quickly. With a background in scalable systems design, he has over a decade of experience working with unicorns and startups, focusing on engineering and generative AI. At Argil, he blends his engineering expertise with a passion for innovation to help creators and businesses efficiently produce personalized video content. His career has been centered on leveraging AI to reduce the barriers to video creation and enhance digital storytelling.",
  imageUrl:
    "https://createsiteai.b-cdn.net/c/_/c4f3755f-cc35-4fea-9e0b-bdeab3fa7e01",
  event: {
    date: "December 17th, 2024",
    time: "8:30 PM - 10:30 PM",
    location: "Brasserie Rosie, Paris 11ème",
  },
  company: {
    name: "Argil",
    description:
      "Argil is an AI-powered platform that enables users to create engaging videos quickly by generating AI avatars. Users can create customized avatars of themselves or others with just a short video, allowing them to produce professional-quality videos in any language for promotion, education, or entertainment. The platform offers tools for controlling body language, camera angles, and editing, making content creation efficient and accessible. Argil is ideal for marketers, educators, and creators seeking scalable, high-quality video production with minimal effort.",
    website: "https://www.argil.ai/",
  },
  socials: [
    {
      type: "linkedin",
      url: "https://www.linkedin.com/in/brivael-le-pogam-bbbb7185/",
    },
    { type: "youtube", url: "https://www.youtube.com/watch?v=DL6oz54zrGw" },
  ],
};
