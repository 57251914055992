import { DaysInMonth } from "../types";

export const getDaysInMonth = (date: Date): DaysInMonth => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const days = new Date(year, month + 1, 0).getDate();
  const firstDay = new Date(year, month, 1).getDay();
  // Ensure we're working with the correct month
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  return { days: daysInMonth, firstDay };
};
