import { Link } from "react-router-dom";

interface ActionButtonsProps {
  expertSlug: string;
  onClose: () => void;
}

export const ActionButtons = ({ expertSlug, onClose }: ActionButtonsProps) => (
  <div className="flex gap-4">
    <Link to={`/diner/${expertSlug}`} className="flex-1">
      <button className="w-full bg-gradient-to-br from-amber-500 via-amber-600 to-orange-600 hover:from-amber-400 hover:via-amber-500 hover:to-orange-500 text-white text-base font-medium px-6 py-3 rounded-xl shadow-lg shadow-amber-900/30 transition-all duration-300 border border-amber-500/20 hover:scale-102 active:scale-98">
        View Details
      </button>
    </Link>
    <button
      onClick={onClose}
      className="flex-1 bg-stone-800/30 hover:bg-stone-800/40 text-white text-base font-medium px-6 py-3 rounded-xl backdrop-blur-sm transition-all duration-300 border border-white/10 hover:border-amber-500/30 hover:scale-102 active:scale-98"
    >
      Close
    </button>
  </div>
);
