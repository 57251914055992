import DinerCarousel from "../../DinerCarousel";

const HeroCarousel = () => {
  return (
    <div className="desktop:col-span-5 laptop:col-span-5 tablet:col-span-12 mobile:col-span-12 px-8 opacity-100 transform translate-x-0 transition-all duration-800 delay-200">
      <DinerCarousel />
    </div>
  );
};

export default HeroCarousel;
