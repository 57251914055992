import { Expert } from "../../../../types";

export const pierreLouisBoijout: Expert = {
  name: "PL Boijout & Stan Girard",
  background:
    "Pierre-Louis Biojout is an AI expert with a background in applied mathematics and computer science from École Polytechnique. His early career in data at Orika set the stage for his work at Phospho.\n\nStan Girard previously served as the Head of Generative AI at Theodo, where he worked on leveraging AI technologies for cloud services and infrastructure, before co-founding Quivr.",
  imageUrl:
    "https://createsiteai.b-cdn.net/c/_/a764210d-47eb-431a-a3a7-8c9f1534adcf",
  event: {
    date: "December 4th, 2024",
    time: "8:30 PM - 10:30 PM",
    location: "Brasserie Rosie, Paris 11ème",
  },
  company: {
    name: "Phospho & Quivr",
    description:
      'Phospho offers analytics tools for text-based large language model (LLM) applications, helping businesses analyze user messages to enhance decision-making and reduce churn through its no-code platform.\n\n\nQuivr is a platform that turns knowledge into actionable insights by creating personalized AI assistants, called "Brains".',
    website: "https://phospho.ai/",
  },
  socials: [
    {
      type: "linkedin",
      url: "https://www.linkedin.com/in/pierre-louis-biojout-9509741aa/",
    },
    { type: "youtube", url: "https://www.youtube.com/watch?v=yieDiiwgfyQ" },
  ],
};
