import Logo from "../../components/Navigation/Logo";
import { useState } from "react";
import { Background } from "./components/Background";
import { CalendarHeader } from "./components/CalendarHeader";
import { CalendarGrid } from "./components/CalendarGrid";
import { EventList } from "./components/EventList";
import { events } from "./data/events";
import { getDaysInMonth } from "./utils/dateUtils";

const Calendar = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date(2024, 10)); // Start with March 2024

  const { days, firstDay } = getDaysInMonth(currentMonth);

  const nextMonth = () => {
    const newDate = new Date(currentMonth);
    newDate.setMonth(currentMonth.getMonth() + 1);
    if (newDate.getFullYear() === 2024) {
      setCurrentMonth(newDate);
    }
  };

  const prevMonth = () => {
    const newDate = new Date(currentMonth);
    newDate.setMonth(currentMonth.getMonth() - 1);
    if (newDate.getFullYear() === 2024) {
      setCurrentMonth(newDate);
    }
  };

  return (
    <div className="relative min-h-screen overflow-hidden">
      <Background />
      <div className="absolute top-0 left-0 w-full z-50 px-8 py-6">
        <div className="max-w-7xl mx-auto">
          <Logo />
        </div>
      </div>
      <div className="relative z-10 desktop:px-24 laptop:px-16 tablet:px-8 mobile:px-4 min-h-screen">
        <div className="opacity-100 transform-none transition-opacity duration-800 max-w-7xl mx-auto py-16">
          <div className="bg-gradient-to-br from-stone-800/40 via-stone-900/40 to-black/40 rounded-3xl p-8 border border-stone-700/20 backdrop-blur-md shadow-2xl">
            <CalendarHeader
              onPrevMonth={prevMonth}
              onNextMonth={nextMonth}
              currentMonth={currentMonth}
            />
            <CalendarGrid
              firstDay={firstDay}
              days={days}
              currentMonth={currentMonth}
              events={events}
            />
            <EventList events={events} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
