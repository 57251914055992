import { Link } from "react-router-dom";

export const BookingButton = () => {
  return (
    <Link to="/register">
      <button className="w-full bg-gradient-to-br from-amber-500 via-amber-600 to-orange-600 hover:from-amber-400 hover:via-amber-500 hover:to-orange-500 text-white text-lg font-medium px-8 py-4 rounded-xl shadow-lg shadow-amber-900/30 transition-all duration-300 border border-amber-500/20 hover:scale-102">
        Book Your Seat
      </button>
    </Link>
  );
};
