import { Expert } from "../../../../types";

export const antoineCarossio: Expert = {
  name: "Antoine Carossio",
  background:
    "Antoine Carossio is a cybersecurity expert and the co-founder of Escape, a company specializing in AI-driven API security. With a background in data science, he has worked for the French Army and interned at Apple, where he focused on machine learning. In 2019, while studying at the University of California, Berkeley, he teamed up with Tristan Kalos to build Escape after identifying the critical need for faster, more effective security tools for developers. Escape uses AI to automate the detection and fixing of security flaws in APIs, helping developers ensure robust, secure application.",
  imageUrl:
    "https://createsiteai.b-cdn.net/c/_/1c7579ed-9b9f-4b77-8476-392abe4bc408",
  event: {
    date: "December 10th, 2024",
    time: "8:30 PM - 10:30 PM",
    location: "Brasserie Rosie, Paris 11ème",
  },
  company: {
    name: "Escape",
    description:
      "Escape is an AI-powered API security platform that enables businesses to discover and secure their APIs quickly and efficiently. The platform provides agentless API discovery, identifies vulnerabilities (including OWASP Top 10 and business logic flaws), and offers actionable remediation guidance. Escape integrates seamlessly into CI/CD pipelines for continuous security, allowing developers to address issues before they reach production. With a focus on compliance, risk prioritization, and seamless integration, Escape helps organizations strengthen their API security posture.",
    website: "https://escape.tech/",
  },
  socials: [
    { type: "linkedin", url: "https://www.linkedin.com/in/acarossio/" },
    { type: "youtube", url: "https://www.youtube.com/watch?v=oIdeaZoo-II" },
  ],
};
