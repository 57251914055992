import { IoChevronBack, IoChevronForward } from "react-icons/io5";

interface CalendarHeaderProps {
  onPrevMonth: () => void;
  onNextMonth: () => void;
  currentMonth: Date;
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const CalendarHeader = ({
  onPrevMonth,
  onNextMonth,
  currentMonth,
}: CalendarHeaderProps) => {
  const month = months[currentMonth.getMonth()];
  const year = currentMonth.getFullYear();

  return (
    <div className="flex justify-between items-center mb-8">
      <div className="space-y-2">
        <h2 className="text-white text-3xl font-semibold">Upcoming Events</h2>
        <p className="text-amber-200/60 text-xl">
          {month} {year}
        </p>
      </div>
      <div className="flex gap-4">
        <button
          onClick={onPrevMonth}
          className="p-2 rounded-full bg-stone-800/50 hover:bg-stone-700/50 text-amber-200/80 transition-transform duration-200 hover:scale-105 active:scale-95"
        >
          <IoChevronBack size={24} />
        </button>
        <button
          onClick={onNextMonth}
          className="p-2 rounded-full bg-stone-800/50 hover:bg-stone-700/50 text-amber-200/80 transition-transform duration-200 hover:scale-105 active:scale-95"
        >
          <IoChevronForward size={24} />
        </button>
      </div>
    </div>
  );
};
