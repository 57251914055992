import { CalendarEvent } from "../../types";
import { DesktopEventCard } from "./DesktopEventCard";
import { MobileEventCard } from "../MobileEventCard";

interface EventListContainerProps {
  events: CalendarEvent[];
}

export const EventListContainer = ({ events }: EventListContainerProps) => (
  <div className="mt-8 space-y-4">
    <h3 className="text-white text-xl font-semibold mb-4">
      Upcoming Events Details
    </h3>
    {/* Desktop and Tablet View */}
    <div className="desktop:block laptop:block tablet:block mobile:hidden">
      {events.map((event, index) => (
        <DesktopEventCard key={index} event={event} index={index} />
      ))}
    </div>

    {/* Mobile View */}
    <div className="desktop:hidden laptop:hidden tablet:hidden mobile:block space-y-4">
      {events.map((event, index) => (
        <MobileEventCard key={index} event={event} index={index} />
      ))}
    </div>
  </div>
);
