import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link to="/">
      <div className="inline-block">
        <div className="flex items-center gap-2">
          <div className="w-10 h-10 rounded-xl bg-gradient-to-br from-amber-500 via-amber-600 to-orange-600 flex items-center justify-center shadow-lg shadow-amber-900/30 border border-amber-500/20">
            <span className="text-white desktop:desktop:text-xl laptop:text-xl tablet:text-lg mobile:text-base laptop:text-xl tablet:text-lg mobile:text-base font-bold">
              AI
            </span>
          </div>
          <span className="text-white text-xl font-semibold">Dinners</span>
        </div>
      </div>
    </Link>
  );
};

export default Logo;
