export const LocationMap = () => {
  return (
    <div>
      <h3 className="text-white text-xl font-semibold mb-4">Location</h3>
      <a
        href="https://www.google.com/maps/search/53+Rue+du+Faubourg+Saint-Antoine,+75011+Paris"
        target="_blank"
        rel="noopener noreferrer"
        className="block w-full h-48 rounded-xl overflow-hidden border border-stone-700/20 mb-8 transition-transform duration-300 hover:scale-102"
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10501.048029642418!2d2.374166!3d48.853214!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e67367208818a7%3A0xac9e5face0a0c3!2sBrasserie%20Rosie!5e0!3m2!1sen!2sus!4v1731262265930!5m2!1sen!2sus"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </a>
    </div>
  );
};
