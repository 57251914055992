import { CalendarEvent } from "../types";

export const events: CalendarEvent[] = [
  {
    date: "2024-11-19",
    time: "8:30 PM - 10:30 PM",
    title: "AI Assistants & Workplace Productivity",
    expert: "Gabriel Hubert",
    location: "Brasserie Rosie, Paris 11ème",
    availableSeats: 10,
  },
  {
    date: "2024-11-21",
    time: "8:30 PM - 10:30 PM",
    title: "NLP & Language Models Innovation",
    expert: "Thomas Scialom",
    location: "Brasserie Rosie, Paris 11ème",
    availableSeats: 10,
  },
  {
    date: "2024-11-28",
    time: "9:00 PM - 10:30 PM",
    title: "Distributed Computing & AI Systems",
    expert: "Rachid Guerraoui",
    location: "Brasserie Rosie, Paris 11ème",
    availableSeats: 10,
  },
  {
    date: "2024-12-04",
    time: "8:30 PM - 10:30 PM",
    title: "LLM Analytics & Performance Optimization",
    expert: "Pierre Louis Boijout",
    location: "Brasserie Rosie, Paris 11ème",
    availableSeats: 10,
  },
  {
    date: "2024-12-05",
    time: "8:30 PM - 10:30 PM",
    title: "AI in Cybersecurity Operations",
    expert: "Hamza Sayah",
    location: "Brasserie Rosie, Paris 11ème",
    availableSeats: 10,
  },
  {
    date: "2024-12-10",
    time: "8:30 PM - 10:30 PM",
    title: "AI-Driven API Security",
    expert: "Antoine Carossio",
    location: "Brasserie Rosie, Paris 11ème",
    availableSeats: 10,
  },
  {
    date: "2024-12-17",
    time: "8:30 PM - 10:30 PM",
    title: "AI Video Generation & Digital Content",
    expert: "Brivael le Pogam",
    location: "Brasserie Rosie, Paris 11ème",
    availableSeats: 10,
  },
  {
    date: "TBD",
    time: "8:30 PM - 10:30 PM",
    title: "Speech-to-Speech AI & Open Science",
    expert: "Laurent Mazare",
    location: "Brasserie Rosie, Paris 11ème",
    availableSeats: 10,
  },
];
