import { EventBottomSheetProps } from "./types";
import { Overlay } from "./Overlay";
import { EventDetailItem } from "./EventDetailItem";
import { ActionButtons } from "./ActionButtons";
import { experts } from "../../../DinerDetails/data/experts";

export const EventBottomSheet = ({ event, onClose }: EventBottomSheetProps) => {
  const eventDetails = [
    { label: "Date", value: event.date },
    { label: "Time", value: event.time },
    { label: "Location", value: event.location },
  ];

  const expertSlug = event.expert.toLowerCase().replace(/\s+/g, "-");
  const expertData = experts.find((e) => e.name === event.expert);

  return (
    <>
      <Overlay onClose={onClose} />
      <div className="fixed desktop:top-auto laptop:top-auto tablet:top-auto mobile:top-0 left-0 right-0 bg-gradient-to-br from-stone-800/95 via-stone-900/95 to-black/95 rounded-b-3xl p-6 z-50 border-b border-stone-700/20 backdrop-blur-lg">
        <div
          className="transform desktop:-translate-y-full laptop:-translate-y-full tablet:-translate-y-full mobile:translate-y-0 animate-slide-down"
          style={{
            animation: "slideDown 0.5s cubic-bezier(0.4, 0, 0.2, 1) forwards",
          }}
        >
          <div className="w-12 h-1 bg-stone-700/50 rounded-full mx-auto desktop:mt-6 laptop:mt-6 tablet:mt-6 mobile:mt-2 mb-6" />
          <div className="space-y-6">
            <div>
              <h3 className="text-white text-2xl font-semibold mb-2">
                {event.title}
              </h3>
              <p className="text-amber-200/60">
                {event.expert}
                {expertData && (
                  <span className="desktop:hidden laptop:hidden tablet:hidden mobile:inline">
                    {" - "}
                    {expertData.company.name}
                  </span>
                )}
              </p>
            </div>
            <div className="space-y-4">
              {eventDetails.map((item, index) => (
                <EventDetailItem key={index} {...item} />
              ))}
            </div>
            <ActionButtons expertSlug={expertSlug} onClose={onClose} />
          </div>
        </div>
      </div>
    </>
  );
};
