import { Link } from "react-router-dom";

const HeroButtons = () => {
  return (
    <div className="flex desktop:flex-row laptop:flex-row tablet:flex-row mobile:flex-col gap-6">
      <Link to="/calendar" className="flex-1">
        <button className="w-full bg-gradient-to-br from-amber-500 via-amber-600 to-orange-600 text-white desktop:text-lg laptop:text-lg tablet:text-base mobile:text-base font-medium px-10 py-5 rounded-2xl shadow-lg shadow-amber-900/30 border border-amber-500/20 hover:from-amber-400 hover:via-amber-500 hover:to-orange-500 transition-all duration-300">
          Browse Events
        </button>
      </Link>
      <Link to="/register" className="flex-1">
        <button className="w-full bg-stone-800/30 text-white desktop:text-lg laptop:text-lg tablet:text-base mobile:text-base font-medium px-10 py-5 rounded-2xl backdrop-blur-sm border border-white/10 hover:border-amber-500/30 hover:bg-stone-800/40 transition-all duration-300">
          Reserve Your Seat
        </button>
      </Link>
    </div>
  );
};

export default HeroButtons;
