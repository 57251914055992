import { SocialLinks } from "./SocialLinks";

interface ExpertInfoProps {
  name: string;
  companyName: string;
  background: string;
  socials: any[];
}

export const ExpertInfo = ({
  name,
  companyName,
  background,
  socials,
}: ExpertInfoProps) => {
  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-white text-5xl font-semibold mb-2">{name}</h2>
        <p className="text-amber-200/60 text-2xl">{companyName}</p>
      </div>
      <div>
        <h3 className="text-white text-3xl font-semibold mb-4">
          About the Experts
        </h3>
        <p className="text-amber-100/80 text-2xl desktop:text-left laptop:text-left tablet:text-left mobile:text-justify mobile:hyphens-auto">
          {background}
        </p>
        <SocialLinks socials={socials} />
      </div>
    </div>
  );
};
