import { IoChevronBack, IoChevronForward } from "react-icons/io5";

interface CarouselButtonProps {
  direction: "left" | "right";
  onClick: () => void;
}

export const CarouselButton = ({ direction, onClick }: CarouselButtonProps) => {
  const Icon = direction === "left" ? IoChevronBack : IoChevronForward;
  const positionClasses =
    direction === "left"
      ? "left-0 -translate-x-1/2"
      : "right-0 translate-x-1/2";

  return (
    <div
      className={`absolute ${positionClasses} top-1/2 -translate-y-1/2 z-20`}
    >
      <button
        onClick={onClick}
        className="p-3 rounded-full bg-stone-800/80 hover:bg-stone-700/80 text-amber-200/80 backdrop-blur-sm border border-stone-700/30"
      >
        <Icon size={24} />
      </button>
    </div>
  );
};
