import { useState } from "react";

interface OptimizedImageProps {
  src: string;
  alt: string;
  className?: string;
}

export const OptimizedImage = ({
  src,
  alt,
  className = "",
}: OptimizedImageProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div
      className={`relative overflow-hidden ${className}`}
      style={{ opacity: isLoaded ? 1 : 0, transition: "opacity 0.5s" }}
    >
      <img
        src={src}
        alt={alt}
        onLoad={() => setIsLoaded(true)}
        className="w-full h-full object-cover object-center"
        loading="lazy"
        style={{
          aspectRatio: "1/1",
        }}
      />
    </div>
  );
};
