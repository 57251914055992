import { Expert } from "../../../../types";

export const thomasScialom: Expert = {
  name: "Thomas Scialom",
  background:
    "Thomas Scialom has built a diverse career in AI research and entrepreneurship, particularly in natural language processing (NLP). After starting his career in finance at Société Générale, he transitioned to AI, studying at École Polytechnique and earning a Ph.D. in AI from Sorbonne Université. Scialom co-founded reciTAL, an AI-driven NLP company, where he leads the NLP research team. His work spans academic research and industry applications, focusing on NLP, language generation, and reinforcement learning. He also teaches AI-related courses and gives industry talks on modern NLP and AI advancements.",
  imageUrl:
    "https://createsiteai.b-cdn.net/c/_/14ef7c41-8457-4e7b-a0a2-37f6d67b13c4",
  event: {
    date: "November 21st, 2024",
    time: "8:30 PM - 10:30 PM",
    location: "Brasserie Rosie, Paris 11ème",
  },
  company: {
    name: "Meta AI (Llama)",
    description:
      "Meta's AI development with its Llama models focuses on creating open-access large language models (LLMs) for research and industry use. With Llama 2, released in partnership with Microsoft, Meta introduced a versatile model lineup aimed at various commercial applications, with sizes reaching up to 70 billion parameters. Recently, Meta launched Llama 3, which expanded on this by increasing language understanding capabilities, enhancing multilingual support, and optimizing for code-related tasks through Code Llama. These models, now deployable through Hugging Face's infrastructure, reflect Meta's commitment to advancing generative AI by enhancing usability, performance, and accessibility for developers and businesses alike.",
    website: "https://huggingface.co/meta-llama",
  },
  socials: [
    { type: "linkedin", url: "https://www.linkedin.com/in/tscialom/" },
    { type: "youtube", url: "https://www.youtube.com/watch?v=zvWzk4OOcIA" },
  ],
};
