import { useState } from "react";
import { EventBottomSheet } from "../EventBottomSheet";
import { WeekdayHeader } from "./WeekdayHeader";
import { CalendarCell } from "./CalendarCell";
import type { CalendarGridProps } from "./types";
import { CalendarEvent } from "../../types";

export const CalendarGrid = ({
  firstDay,
  days,
  currentMonth,
  events,
}: CalendarGridProps) => {
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(
    null,
  );
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);

  const handleDateClick = (event: CalendarEvent | undefined) => {
    if (event) {
      setSelectedEvent(event);
      setIsBottomSheetOpen(true);
    }
  };

  const getEventForDate = (index: number) => {
    const date = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      index + 1,
    );
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const dateStr = `${year}-${month}-${day}`;
    return events.find((e) => e.date === dateStr);
  };

  return (
    <>
      <WeekdayHeader />

      <div className="grid grid-cols-7 gap-2">
        {Array.from({ length: firstDay }).map((_, index) => (
          <div key={`empty-${index}`} className="aspect-square" />
        ))}

        {Array.from({ length: days }).map((_, index) => {
          const event = getEventForDate(index);
          return (
            <CalendarCell
              key={index}
              index={index}
              event={event}
              onClick={() => handleDateClick(event)}
            />
          );
        })}
      </div>

      {isBottomSheetOpen && selectedEvent && (
        <EventBottomSheet
          event={selectedEvent}
          onClose={() => setIsBottomSheetOpen(false)}
        />
      )}
    </>
  );
};
