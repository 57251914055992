import { Link } from "react-router-dom";
import { CalendarEvent } from "../../../types";

interface DesktopViewProps {
  index: number;
  event: CalendarEvent | undefined;
}

export const DesktopView = ({ index, event }: DesktopViewProps) => (
  <div className="desktop:block laptop:block tablet:block mobile:hidden h-full">
    <Link
      to={
        event
          ? `/diner/${event.expert.toLowerCase().replace(/\s+/g, "-")}`
          : "#"
      }
      className={`h-full flex flex-col ${
        event ? "cursor-pointer" : "cursor-default"
      }`}
    >
      <span className={`text-sm ${event ? "text-amber-200" : "text-white/60"}`}>
        {index + 1}
        {event ? ` - ${event.expert}` : ""}
      </span>
      {event && (
        <div className="mt-auto">
          <div className="text-xs text-amber-300/80 truncate max-w-[120px]">
            {event.title}
          </div>
          <div className="text-[10px] text-amber-200/50 truncate max-w-[120px]">
            {event.time}
          </div>
        </div>
      )}
    </Link>
  </div>
);
