import { CalendarEvent } from "../../../types";

interface MobileViewProps {
  index: number;
  event: CalendarEvent | undefined;
}

export const MobileView = ({ index, event }: MobileViewProps) => (
  <div className="desktop:hidden laptop:hidden tablet:hidden mobile:flex flex-col items-center justify-center h-full">
    <span
      className={`text-sm truncate max-w-[80px] ${event ? "text-amber-200" : "text-white/60"}`}
    >
      {index + 1}
    </span>
    {event && <div className="w-1.5 h-1.5 rounded-full bg-amber-500 mt-1" />}
  </div>
);
