import { Expert } from "../../../../types";

export const rachidGuerraoui: Expert = {
  name: "Rachid Guerraoui",
  background:
    "Rachid Guerraoui is a prominent computer scientist specializing in distributed algorithms and computing systems. He currently heads the Distributed Computing Laboratory (DCL) at École Polytechnique Fédérale de Lausanne (EPFL). His work spans distributed algorithms, consensus mechanisms, and secure data storage, which have influenced fields such as e-transactions and information dissemination. Guerraoui's contributions have earned him prestigious awards, including the Dahl-Nygaard Prize, recognizing his impact on programming languages and software engineering. He has also held academic and research positions at leading institutions, including MIT and Hewlett-Packard Labs.",
  imageUrl:
    "https://createsiteai.b-cdn.net/c/_/e481ed68-a5d2-461b-9f1d-df4bee9bc4dc",
  event: {
    date: "November 28th, 2024",
    time: "9:00 PM - 10:30 PM",
    location: "Brasserie Rosie, Paris 11ème",
  },
  company: {
    name: "EPFL AI Center",
    description:
      "The École Polytechnique Fédérale de Lausanne (EPFL) is deeply engaged in advancing artificial intelligence (AI) across multiple domains, from health and robotics to foundational theory and responsible AI practices. Through its AI Center, EPFL fosters interdisciplinary collaboration to develop practical and ethical AI solutions. It also hosts events like the Applied Machine Learning Days (AMLD), uniting academic, industry, and public sectors to discuss AI's impact and potential risks. The center's work aligns with international AI safety initiatives, aiming to promote sustainable, inclusive, and transparent AI research and applications.",
    website: "https://ai.epfl.ch/",
  },
  socials: [
    {
      type: "linkedin",
      url: "https://www.linkedin.com/in/rachid-guerraoui-40587b15/",
    },
    { type: "youtube", url: "https://www.youtube.com/watch?v=E30b-IayGA4" },
  ],
};
