import { IoCalendarClear, IoTime, IoLocation } from "react-icons/io5";

interface EventDetailsProps {
  date: string;
  time: string;
  location: string;
}

export const EventDetails = ({ date, time, location }: EventDetailsProps) => (
  <div className="p-4 space-y-3">
    <div className="flex items-center gap-3">
      <IoCalendarClear className="text-amber-200/60 w-5 h-5" />
      <span className="text-amber-200/80 text-sm">{date}</span>
    </div>
    <div className="flex items-center gap-3">
      <IoTime className="text-amber-200/60 w-5 h-5" />
      <span className="text-amber-200/60 text-sm">{time}</span>
    </div>
    <div className="flex items-center gap-3">
      <IoLocation className="text-amber-200/60 w-5 h-5" />
      <span className="text-amber-200/60 text-sm">{location}</span>
    </div>
  </div>
);
