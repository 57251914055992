import { OptimizedImage } from "../../../../../components/OptimizedImage";
import { Expert } from "../../../data/experts";
import { EventDetails } from "../../components/EventDetails";
import { LocationMap } from "../../components/LocationMap";
import { ExpertInfo } from "../../components/ExpertInfo";
import { CompanySection } from "../../components/CompanySection";
import { BookingButton } from "../../components/BookingButton";

interface DesktopLayoutProps {
  expert: Expert;
}

export const DesktopLayout = ({ expert }: DesktopLayoutProps) => {
  return (
    <div className="desktop:grid laptop:grid tablet:grid mobile:hidden desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-1 gap-8">
      <div className="desktop:col-span-1 laptop:col-span-1 tablet:col-span-1 space-y-8">
        <div>
          <OptimizedImage
            src={expert.imageUrl}
            alt={expert.name}
            className="rounded-2xl border border-stone-700/20"
          />
        </div>
        <EventDetails
          date={expert.event.date}
          time={expert.event.time}
          location={expert.event.location}
        />
        <LocationMap />
      </div>
      <div className="desktop:col-span-2 laptop:col-span-2 tablet:col-span-1 space-y-8">
        <div>
          <ExpertInfo
            name={expert.name}
            companyName={expert.company.name}
            background={expert.background}
            socials={expert.socials}
          />
        </div>
        <div>
          <CompanySection
            description={expert.company.description}
            website={expert.company.website}
          />
        </div>
        <div className="flex justify-center">
          <div className="desktop:w-2/3 laptop:w-2/3 tablet:w-full">
            <BookingButton />
          </div>
        </div>
      </div>
    </div>
  );
};
