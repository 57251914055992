interface EventDetailsProps {
  date: string;
  time: string;
  location: string;
}

export const EventDetails = ({ date, time, location }: EventDetailsProps) => {
  const details = [
    { label: "Date", value: date },
    { label: "Time", value: time },
    { label: "Location", value: location },
  ];

  return (
    <div>
      <h3 className="text-white text-xl font-semibold mb-4">Event Details</h3>
      <div className="space-y-4">
        {details.map((item, index) => (
          <div
            key={index}
            className="flex justify-between items-center py-2 border-b border-stone-700/20"
          >
            <span className="text-amber-200/60 font-light">{item.label}</span>
            <span className="text-white/90 font-medium">{item.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
