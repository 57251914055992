import { Link } from "react-router-dom";

interface EventActionsProps {
  availableSeats: number;
  expertSlug: string;
}

export const EventActions = ({
  availableSeats,
  expertSlug,
}: EventActionsProps) => (
  <div className="p-4 border-t border-stone-700/20">
    <div className="flex items-center justify-between mb-4">
      <span className="inline-block bg-emerald-500/10 text-emerald-300 px-3 py-1 rounded-full text-sm border border-emerald-500/20">
        {availableSeats} Seats Available
      </span>
    </div>
    <div className="flex flex-col gap-2">
      <Link to="/register" className="w-full">
        <button className="w-full bg-gradient-to-br from-amber-500 via-amber-600 to-orange-600 hover:from-amber-400 hover:via-amber-500 hover:to-orange-500 text-white text-sm font-medium px-6 py-2 rounded-xl shadow-lg shadow-amber-900/30 transition-all duration-300 border border-amber-500/20 hover:scale-105 active:scale-95">
          Reserve
        </button>
      </Link>
      <Link to={`/diner/${expertSlug}`} className="w-full">
        <button className="w-full bg-stone-800/30 hover:bg-stone-800/40 text-white text-sm font-medium px-6 py-2 rounded-xl backdrop-blur-sm transition-all duration-300 border border-white/10 hover:border-amber-500/30 hover:scale-105 active:scale-95">
          Find out more
        </button>
      </Link>
    </div>
  </div>
);
