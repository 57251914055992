interface OverlayProps {
  onClose: () => void;
}

export const Overlay = ({ onClose }: OverlayProps) => (
  <div
    className="fixed inset-0 bg-black opacity-0 transition-opacity duration-300"
    style={{
      animation: "fadeIn 0.3s ease-out forwards",
    }}
    onClick={onClose}
  />
);
