import { Expert } from "../../../../types";

export const hamzaSayah: Expert = {
  name: "Hamza Sayah",
  background:
    "Hamza Sayah is a co-founder of Qevlar, a cybersecurity startup specializing in AI-driven solutions for Security Operations Centers (SOCs). Qevlar focuses on automating threat detection and investigation processes, using AI agents that operate autonomously and integrate into various tech environments. Prior to founding Qevlar, Hamza worked in cybersecurity roles, applying his expertise to help organizations streamline security operations and accelerate response times. Qevlar's recent seed funding aims to further develop its AI technology to improve security efficiency across industries.",
  imageUrl:
    "https://createsiteai.b-cdn.net/c/_/81f018eb-5bc8-4f78-8619-205f87562fc9",
  event: {
    date: "December 5th, 2024",
    time: "8:30 PM - 10:30 PM",
    location: "Brasserie Rosie, Paris 11ème",
  },
  company: {
    name: "Qevlar",
    description:
      "Qevlar is an AI-driven cybersecurity platform designed to autonomously manage Security Operations Center (SOC) investigations. It automates alert analysis across various attack vectors, prioritizing threats and offering actionable insights without relying on predefined playbooks. With a focus on end-to-end investigation efficiency, Qevlar enables SOC teams to handle threats faster and reduce false positives. The platform integrates seamlessly with existing security stacks to ensure consistent protection.",
    website: "https://www.qevlar.com/",
  },
  socials: [
    { type: "linkedin", url: "https://www.linkedin.com/in/hamza-sayah/" },
    { type: "youtube", url: "https://www.youtube.com/watch?v=H-_NqIRClhE" },
  ],
};
