import { EventHeader } from "./EventHeader";
import { EventDetails } from "./EventDetails";
import { EventActions } from "./EventActions";
import type { MobileEventCardProps } from "./types";

export const MobileEventCard = ({ event, index }: MobileEventCardProps) => (
  <div className="rounded-2xl bg-gradient-to-br from-stone-800/40 via-stone-900/40 to-stone-900/40 border border-stone-700/20 backdrop-blur-sm overflow-hidden">
    <EventHeader title={event.title} expert={event.expert} />
    <EventDetails
      date={event.date}
      time={event.time}
      location={event.location}
    />
    <EventActions
      availableSeats={event.availableSeats}
      expertSlug={event.expert.toLowerCase().replace(/\s+/g, "-")}
    />
  </div>
);
