import { Link } from "react-router-dom";

interface EventButtonsProps {
  expertSlug: string;
}

export const EventButtons = ({ expertSlug }: EventButtonsProps) => (
  <div className="flex gap-2">
    <Link to="/register">
      <button className="bg-gradient-to-br from-amber-500 via-amber-600 to-orange-600 hover:from-amber-400 hover:via-amber-500 hover:to-orange-500 text-white text-sm font-medium px-6 py-2 rounded-xl shadow-lg shadow-amber-900/30 transition-all duration-300 border border-amber-500/20 hover:scale-102 active:scale-98">
        Reserve
      </button>
    </Link>
    <Link to={`/diner/${expertSlug}`}>
      <button className="bg-stone-800/30 hover:bg-stone-800/40 text-white text-sm font-medium px-6 py-2 rounded-xl backdrop-blur-sm transition-all duration-300 border border-white/10 hover:border-amber-500/30 hover:scale-102 active:scale-98">
        Find out more
      </button>
    </Link>
  </div>
);
