const stats = [
  {
    number: "10",
    label: "Students per Evening",
    sublabel: "Intimate gathering",
  },
  {
    number: "10",
    label: "AI Experts",
    sublabel: "Industry leaders",
  },
  {
    number: "2h",
    label: "of Exchange",
    sublabel: "Deep discussions",
  },
];

const Stats = () => {
  return (
    <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-3 mobile:grid-cols-1 desktop:gap-6 laptop:gap-6 tablet:gap-4 mobile:gap-3 desktop:mt-16 laptop:mt-16 tablet:mt-12 mobile:mt-8">
      {stats.map((stat, index) => (
        <div
          key={index}
          className="desktop:p-6 laptop:p-6 tablet:p-4 mobile:p-4 rounded-2xl bg-gradient-to-br from-stone-800/40 via-stone-900/40 to-stone-900/40 border border-stone-700/20 backdrop-blur-sm transition-all duration-300"
        >
          <div className="text-amber-300 desktop:text-4xl laptop:text-4xl tablet:text-3xl mobile:text-3xl font-bold mb-2">
            {stat.number}
          </div>
          <div className="text-amber-100/90 desktop:text-sm laptop:text-sm tablet:text-sm mobile:text-sm font-medium mb-1">
            {stat.label}
          </div>
          <div className="text-amber-200/50 text-xs font-light">
            {stat.sublabel}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Stats;
