import Logo from "../../components/Navigation/Logo";
import { Link } from "react-router-dom";
import { IoAlertCircle } from "react-icons/io5";
import { Background } from "./components/Background";

export const RegistrationFailed = () => {
  return (
    <div className="relative min-h-screen overflow-hidden">
      <Background />
      <div className="absolute top-0 left-0 w-full z-50 px-8 py-6">
        <div className="max-w-7xl mx-auto">
          <Logo />
        </div>
      </div>
      <div className="relative z-10 desktop:px-24 laptop:px-16 tablet:px-8 mobile:px-4 min-h-screen flex items-center justify-center">
        <div className="w-full max-w-xl opacity-100 translate-y-0">
          <div className="bg-gradient-to-br from-stone-800/40 via-stone-900/40 to-black/40 rounded-3xl p-8 border border-stone-700/20 backdrop-blur-md shadow-2xl text-center">
            <div className="flex justify-center mb-8">
              <IoAlertCircle className="text-red-400 w-24 h-24" />
            </div>
            <h2 className="text-white text-3xl font-semibold mb-4">
              Registration Failed
            </h2>
            <p className="text-amber-100/80 text-lg mb-8">
              Something went wrong while processing your registration. Please
              try again.
            </p>
            <Link to="/register">
              <button className="w-full bg-gradient-to-br from-amber-500 via-amber-600 to-orange-600 hover:from-amber-400 hover:via-amber-500 hover:to-orange-500 text-white text-lg font-medium px-8 py-4 rounded-xl shadow-lg shadow-amber-900/30 transition-all duration-300 border border-amber-500/20">
                Try Again
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
