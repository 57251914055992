const Background = () => {
  return (
    <div className="absolute inset-[-10%] z-0">
      <div
        className="absolute inset-0 scale-110"
        style={{
          backgroundImage: `url('https://createsiteai.b-cdn.net/c/_/ef77ea50-e045-4bc6-aeaf-d486c07f065c')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          filter: "brightness(0.3)",
        }}
      />
      <div className="absolute inset-[-10%] bg-gradient-to-br from-amber-950/80 via-stone-900/75 to-stone-950/85" />
      <div className="absolute inset-[-10%] bg-[radial-gradient(ellipse_at_center,rgba(251,191,36,0.15)_0%,rgba(120,53,15,0.1)_50%,rgba(0,0,0,0)_100%)]" />
      <div className="absolute inset-0 opacity-20 mix-blend-overlay">
        <div
          className="w-full h-full"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")`,
          }}
        />
      </div>
    </div>
  );
};

export default Background;
